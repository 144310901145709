import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/modus-react-bootstrap/modus-react-bootstrap/docs/src/layouts/MainLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>
  All notable changes of the current major version are described in the{' '}
  <a href='https://github.com/trimble-oss/modus-react-bootstrap/releases' target='_blank' rel='noopener'>
    Release notes
  </a>
  . Changes of older versions are described in the <a href='https://github.com/trimble-oss/modus-react-bootstrap/blob/main/CHANGELOG-old.md' target='_blank' rel='noopener'>
    CHANGELOG.old.md
  </a> file.
    </p>
    <p>
  The project adheres to{' '}
  <a href='https://semver.org/spec/v2.0.0.html' target='_blank' rel='noopener'>
    Semantic Versioning 2.0.0
  </a>
  .
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      